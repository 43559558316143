// flow
import React, { useState, useEffect, memo } from "react"
import isEqual from "lodash/isEqual"
import { API, graphqlOperation } from "aws-amplify"
import type { UserType } from "../../type/UserType"
import type { MyAccountPageType } from "../../types/MyAccountPageType"
import InformationWrapper from "../InformationWrapper"
import InformationRow from "../InformationRow"

import {
  ToggleButton,
  Notification,
  handleErrorWithPrismic,
  NotificationToast,
  htmlSerializerUpdateStyle,
  Icon,
  linkResolver,
  propsWithUniqueKey,
} from "@lesmills/gatsby-theme-common"
import { UpdateSubcomms } from "../../graphql/updateSubcomms"
import { RichText, Elements } from "prismic-reactjs"

type Props = {|
  emailReferencesSection: Object,
  sectionStyles?: Object,
  user?: UserType,
  data?: MyAccountPageType,
|}

const EmailReferencesSection = ({
  emailReferencesSection,
  sectionStyles = {},
  user = {},
  data = {},
}: Props) => {
  const [subscribed, setSubscribed] = useState({})
  const [isProcessing, setIsProcessing] = useState(false)
  const [newsLetterLabel = { name: {}, description: {} }] =
    emailReferencesSection.items || []
  const [error, setError] = useState({
    message: "",
  })
  const [newslettersDisabledMsg, setNewslettersDisabledMsg] = useState(null)

  const {
    continue_current_subscription_error = {},
    email_sent_message = {},
    one_moment = {},
    newsletters_disabled_message = {},
    resend_email_message = {},
  } = data

  useEffect(() => {
    /**
      Three cases for subscribing newsletter status:
      1. subComms: false: Toggle will be off, message will be hided
      2. subComms: true & subCommsConfirmed: false: Toggle will be off, message will be showed
      3. subComms: true & subCommsConfirmed: true: Toggle will be on, message will be hided
     */
    setSubscribed({
      subComms: user.subComms,
      subCommsConfirmed: user.subCommsConfirmed,
    })
  }, [user.subComms, user.subCommsConfirmed])

  const htmlSerializerAddParamUrl = (type, element, children, key) => {
    let props = {}

    if (type === Elements.strong) {
      // Find resend text to replace by a button
      props = Object.assign({
        onClick: () => handleChangeSubscribe(true), // set subComs is true to resend mail
        className: "inline underline",
      })

      return React.createElement(
        "button",
        propsWithUniqueKey(props, key),
        children
      )
    }
  }

  const handleChangeSubscribe = async value => {
    setIsProcessing(true)

    try {
      let res = await API.graphql(
        graphqlOperation(UpdateSubcomms, {
          input: {
            subComms: value,
          },
        })
      )

      res = res.data && res.data.updateAxiiUser

      setIsProcessing(false)

      // Reset error message after toggling successfully
      setError({ message: "" })
      setSubscribed({ ...res })
      setNewslettersDisabledMsg(
        !res.subComms ? newsletters_disabled_message : null
      )
    } catch (err) {
      setIsProcessing(false)
      handleErrorWithPrismic(
        err.errors || [],
        continue_current_subscription_error.text,
        setError,
        data
      )
    }
  }

  const subCommsConfirmed = subscribed.subCommsConfirmed
  const subComms = subscribed.subComms
  const isWaitingConfirm = subComms && !subCommsConfirmed

  return (
    <InformationWrapper
      title={
        emailReferencesSection.primary &&
        emailReferencesSection.primary.section_title.text
      }
      classNames={sectionStyles}
    >
      <div className="flex items-top justify-between relative">
        <InformationRow
          label={newsLetterLabel.name.text}
          value={newsLetterLabel.description.text} //FIXME: Please update Email preferences
          classNames={{ wrapper: " w-4/5" }}
          testId="new-letters"
        />
        <ToggleButton
          classNames="md:mt-4 mt-10"
          checked={subComms}
          handleOnToggle={() => handleChangeSubscribe(!subComms)}
          disabled={isProcessing || isWaitingConfirm}
        />
      </div>
      {isProcessing && isWaitingConfirm ? (
        <div className="flex items-center">
          <div className="loader-animation ease-linear rounded-full border-gray-75 h-8 w-8 border-4 border-t-4 default-loader mr-15" />
          <RichText
            render={one_moment.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "font-base-light text-white sm:text-base text-3xs leading-22 text-gray-800"
            )}
          />
        </div>
      ) : (
        <>
          {isWaitingConfirm && (
            <div className="font-base-light text-gray-800 text-3xs">
              <div className="bg-orange-600 py-4 px-20 flex items-center accordion-item-white mb-15">
                <Icon
                  icon="info-active"
                  type="base"
                  classNames="black-back-icon"
                />
                <div className="ml-20">
                  <RichText
                    render={email_sent_message.raw}
                    htmlSerializer={htmlSerializerUpdateStyle(
                      "",
                      "font-base-light text-white sm:text-base text-3xs leading-22"
                    )}
                  />
                </div>
              </div>
              <RichText
                render={resend_email_message.raw}
                htmlSerializer={htmlSerializerAddParamUrl}
                linkResolver={linkResolver}
              />
            </div>
          )}
          {newslettersDisabledMsg && (
            <NotificationToast
              type="success"
              showHideIcon
              handleHideToast={() => setNewslettersDisabledMsg(null)}
            >
              <div className="flex items-center">
                <i className="fa fa-check text-white text-base" />
                <div className="ml-20">
                  <RichText
                    render={newsletters_disabled_message.raw}
                    htmlSerializer={htmlSerializerUpdateStyle(
                      "",
                      "font-base-light text-white sm:text-base text-3xs leading-22"
                    )}
                  />
                </div>
              </div>
            </NotificationToast>
          )}
        </>
      )}
      {error.message && (
        <Notification
          message={error.message}
          type="error"
          classNames={{
            wrapper: "w-auto",
          }}
        />
      )}
    </InformationWrapper>
  )
}

export default memo(EmailReferencesSection, (prevProps, nextProps) => {
  return isEqual(prevProps.user, nextProps.user)
})
